<script>
  import Base from '@backend/Base.vue';
  import BOGen from '@helper/BOGen';
  // import Gen from '@helper/Gen';
  import draggable from 'vuedraggable'

  export default {
    name: "BoEvent",
    extends: Base,
    components: {
      draggable
    },
    data() {
      return {
        Name: "BoEvent",
        hero: {},
        statusEvent: {},
        titleLength:0,
        descLength:0,
        webSettings:{},
      }
    },
    mounted() {
      this.$set(this.$root, 'page', this)
      this.refreshData()
    },
    methods: {
      endDrag() {
        BOGen.apirest('/' + this.Name, { data: this.data.data, type: 'sort' }, (err, resp) => {
          console.log(resp)
        }, "POST");
      },
      submitHero(){
        this.hero.type = 'submitHero'
        BOGen.apirest('/' + this.Name, this.hero, (err, resp) => {
          if(err) return Gen.info((err.resp.message||"please fill mandatory fields"), "danger",3000, ".col-info")
          Gen.info((resp.message),"success",3000,".col-info")
          setTimeout(() => { $('#collapseExample').hide() }, 3000);
          console.log(resp)
        }, "POST");
      },
      submitView() {
          BOGen.apirest('/' + this.Name, {
            data: this.webSettings,
            type: 'updateView'
          }, (err, resp) => {
            if (resp.error) return Gen.info(resp.message, resp.style, 3000, '.info')
            if (resp.success) {
              Gen.info(resp.message, resp.style, 3000, '.info')
              this.refreshData()
            }
          }, "POST");
      },
      showEdit(){
        if($("#collapseExample").css("display")=="flex")
          $('#collapseExample').hide()
        else 
          $('#collapseExample').css("display", "flex")
      }
    },
    watch: {
      '$route.query'(v) {
        if(this.$route.params.id){
          this.$set(this.row, 'statusEvent', this)
          this.$set(this.row, 'search', this)
          setTimeout(()=>{
            this.row.statusEvent = this.$route.query.statusEvent
            this.row.search = this.$route.query.search
          },500)
        }
        this.refreshData()
      },
      'row.me_title'(v) {
        this.titleLength=v.length
      },
      'row.me_short_desc'(v) {
        this.descLength=v.length
      },
      'filter.statusEvent'(v) {
        if (!v) return
        this.search()
      },
    }
  };
</script>

<template>
  <div class="container-fluid">
    <PageTitle></PageTitle>

    <div class="row">

      <div class="col-12" v-if="!$route.params.id">
        <div class="card">
           <div class="card-header">
             <h5 class="card-title mb-0">Hero Image
              </h5>
           </div>

          <VForm @resp="submitHero" method="post">
            <div class="card-body">
              <div class="alert alert-info">
                Image Requirements for Hero Image :
                <ul class="mb-0 mt-2">
                  <li><strong>Dimension: </strong><span>1350x420px (Desktop), 768x1285px (Mobile)</span></li>
                  <li><strong>Format: </strong><span>jpeg,jpg,png</span></li>
                  <li><strong>Max. Size: </strong><span>2 MB</span></li>
                </ul>
              </div>
              <div class="row">
                <div class="col-md-12"></div>
                <div class="col-md-8">
                  <div class="wrap_slider_img">
                    <img :src="uploader(hero.ash_img,'850')" class="img-responsive" />
                    <div class="slider_name">
                      <p>Hero Image</p>
                    </div>
                    <a href="javascript:;" v-if="page.moduleRole('Edit')" @click="showEdit" class="bullet_edit"><i
                        class="fas fa-pencil-alt"></i></a>
                  </div>
                </div>
              </div>
              <div class="row collapse mt-4" id="collapseExample">
								<div class="col-info col-12"></div>
								<div class="col-sm-3 mb-3">
									<BoField name="ash_mobile_img">
										<Uploader v-bind="validation('ash_mobile_img')" :attr="{required:1}" :param="{thumbnail:true,img_ratio:768/1285,rule_size:[768,1285]}" name="ash_mobile_img" v-model="hero.ash_mobile_img" type="hero_mobile" uploadType="cropping"></Uploader>
									</BoField>
								</div>
								<div class="col-sm-9">
									<BoField name="ash_img">
										<Uploader v-bind="validation('ash_img')" :attr="{required:1}" :param="{thumbnail:true,img_ratio:1350/418,rule_size:[1350,418]}" name="ash_img" v-model="hero.ash_img" type="hero_desktop" uploadType="cropping"></Uploader>
									</BoField>
								</div>
								<div class="col-4 col-md-3 mt-3">
									<button type="submit" class="btn btn-rounded btn-block btn-info btn-loading">Submit</button>
								</div>
              </div>
            </div>
          </VForm>
        </div>
      </div>
      <div class="col-12 mb-3" v-if="!$route.params.id">
        <div class="card">
          <div class="card-header">
            <h5>View article by</h5>
          </div>
          <div class="card-body " style="border: 1px solid #f7f7f7;">
            <div class="info"></div>
            <VForm @resp="submitView" class="row">
              <div class="col-6">
                <BoField name="aws_val">
                  <div class="row">
                    <radio name="aws_val" v-model="webSettings.aws_val" option="P" :attr="validation('aws_val')">Publish Date</radio>
                    <radio name="aws_val" v-model="webSettings.aws_val" option="S">Sorting</radio>
                  </div>
                </BoField>
              </div>
              <div class="col-6 text-right">
                <button type="submit" class="fcbtn btn btn-info btn-outline btn-1e btn-loading" style="margin-top: 3%;">Update <i class="icon-arrow-right14 position-right"></i></button>
              </div>
            </VForm>
          </div>
        </div>
      </div>
      <div class="col-12" v-if="!$route.params.id">
        <div class="card">
          <div class="card-header">
            <VForm @resp="search">
              <div class="row">
                <div class="col-sm-6">
                  <h5 class="card-title mb-0">Event List</h5>
                </div>
                <div class="col-sm-2">
                  <select2 :options="statusEvent" v-model="filter.statusEvent">
                    <option value="">-- Select Status --</option>
                  </select2>
                </div>
                <div class="col-sm-3">
                  <div class="form-group mb-0">
                    <div class="input-group">
                      <input type="text" v-model="filter.search" v-on:keyup.enter="search" class="form-control"
                        placeholder="Search...">
                      <div class="input-group-append">
                        <button class="btn btn-info" type="button" @click="search()">
                          <i class="fas fa-search"></i>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-sm-1">
                  <router-link :to="{name:Name}" class="btn btn-warning">Reset</router-link>
                </div>
              </div>
            </VForm>
          </div>

          <div class="card-body">
            <div class="row" v-if="!$route.params.id">
              <div class="col-12">
                <draggable v-model="data.data" tag="div" class="row" @end="endDrag">
                  <div class="col-md-3 mb-3" v-for="(v,k) in data.data" :key="k">
                    <div class="item-list collection">
                      <div class="row text-center">
                        <div class="col-md-12">
                          <div class="product-img recipe-cat mb-2">
                            <img :src="uploader(v.me_image,'250')" alt="prod">
                            <div class="pro-img-overlay">
                              <router-link class="bg-info" :to="{name:Name,params:{id:v.id},query:$route.query}" v-tooltip="'Edit'"><i
                                  class="ti-marker-alt"></i></router-link>
                              <a href="javascript:;" class="bg-danger" @click="deleteItem($event,k)"
                                v-tooltip="'Remove'"><i class="ti-trash"></i></a>
                            </div>
                            <span class="label label-success" v-if="v.me_is_active=='Y'">Active</span>
                            <span class="label label-warning" v-else-if="v.me_is_active=='A'">Archive</span>
                            <span class="label label-danger" v-else>Inactive</span>
                          </div>
                          <small class="text-danger mt-2">{{v.category}}</small>
                          <h5 class="card-title mt-1">{{v.me_title}}</h5>
                        </div>
                      </div>
                    </div>
                  </div>
                </draggable>
                <div v-if="NotFound" class="text-center col-md-12">
                  <h5 class="tc">{{NotFound}}</h5>
                </div>
                <div v-if="data.data===false" class="text-center col-md-12">
                  <LoadingSpinner light></LoadingSpinner>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-12 justify-content-end">
                <Pagination :data="data" :limit="3" @pagination-change-page="onPaging"></Pagination>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-12 card" v-if="$route.params.id">
        <VForm @resp="submitForm" method="post">
          <div class="card-body">
            <div class="row mb-3">
              <div class="col-md-8">
                <h5 class="card-title">{{(row.id?"Edit":"Add")+" "+ObjectName}}</h5>
              </div>
            </div>
            <div class="info"></div>
            <div class="row">
              <div class="col-sm-7">
                <BoField name="me_title" v-model="row.me_title"></BoField>
                <small class="mb-2 d-inline-block text-info">{{validation('me_title').maxlength-titleLength}} character remaining </small>
                <BoField name="me_short_desc" mandatory>
                  <textarea name="me_short_desc" v-model="row.me_short_desc" class="form-control" v-bind="validation('me_short_desc')"></textarea>
                </BoField>  
                <small class="mb-2 d-inline-block text-info">{{validation('me_short_desc').maxlength-descLength}} character remaining </small>
                
                <BoField name="me_desc" mandatory>
                  <CKEditor name="me_desc" v-model="row.me_desc" v-bind="validation('me_desc')"></CKEditor>
                </BoField>    

                <div class="row mb-3">
                  <div class="col-md-8">
                    <h5 class="card-title">Meta Content</h5>
                  </div>
                </div>
                <div class="row">
                  <div class="col-sm-12">
                    <BoField name="me_seo_title" :attr="{minlength:'3'}" v-model="row.me_seo_title" mandatory></BoField>
                    <BoField name="me_seo_keyword" mandatory>
                      <TagsInput name="me_seo_keyword" v-model="row.me_seo_keyword"
                      :attr="validation('me_seo_keyword')"></TagsInput>
                    </BoField>
                  </div>
                  <div class="col-sm-12">
                    <BoField name="me_seo_desc" mandatory>
                      <textarea name="me_seo_desc" rows="5"  :attr="{minlength:'10'}" v-model="row.me_seo_desc" v-bind="validation('me_seo_desc')"
                      class="form-control"></textarea>
                    </BoField>
                  </div>
                </div>
                <div class="row">
                  <div class="col-7">
                    <BoField name="me_is_active">
                      <div class="row">
                        <radio name="me_is_active" v-model="row.me_is_active" option="Y" :attr="validation('me_is_active')">Active</radio>
                        <radio name="me_is_active" v-model="row.me_is_active" option="A">Archive</radio>
                        <radio name="me_is_active" v-model="row.me_is_active" option="N">Inactive</radio>
                      </div>
                    </BoField>
                  </div>
                </div>
              </div>
              <div class="col-sm-5">
                <BoField name="me_image">
                  <Uploader name="me_image" type="recipe" uploadType="cropping" :param="{thumbnail:true}" v-model="row.me_image"></Uploader>
                </BoField>
                <BoField name="me_author" v-model="row.me_author"></BoField>
                <BoField name="me_publish_date">
                  <DatePicker name="me_publish_date" v-model="row.me_publish_date" v-bind="validation('me_publish_date')"></DatePicker>
                </BoField>

              </div>
              <div class="col-sm-12">
                <div class="text-right">
                  <button type="submit" class="fcbtn btn btn-info btn-outline btn-1e btn-loading">Submit form 
                    <i class="icon-arrow-right14 position-right"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </VForm>
      </div>
    </div>
  </div>
</template>